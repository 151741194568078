exports.components = {
  "component---src-pages-2020-alg-js": () => import("./../../../src/pages/2020-alg.js" /* webpackChunkName: "component---src-pages-2020-alg-js" */),
  "component---src-pages-2021-alg-js": () => import("./../../../src/pages/2021-alg.js" /* webpackChunkName: "component---src-pages-2021-alg-js" */),
  "component---src-pages-2022-alg-js": () => import("./../../../src/pages/2022-alg.js" /* webpackChunkName: "component---src-pages-2022-alg-js" */),
  "component---src-pages-2023-alg-js": () => import("./../../../src/pages/2023-alg.js" /* webpackChunkName: "component---src-pages-2023-alg-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-editions-js": () => import("./../../../src/pages/editions.js" /* webpackChunkName: "component---src-pages-editions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-resource-js": () => import("./../../../src/pages/media-resource.js" /* webpackChunkName: "component---src-pages-media-resource-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-register-townhall-js": () => import("./../../../src/pages/register-townhall.js" /* webpackChunkName: "component---src-pages-register-townhall-js" */),
  "component---src-pages-speakers-clare-akamanzi-js": () => import("./../../../src/pages/speakers/ClareAkamanzi.js" /* webpackChunkName: "component---src-pages-speakers-clare-akamanzi-js" */),
  "component---src-pages-speakers-james-magara-js": () => import("./../../../src/pages/speakers/JamesMagara.js" /* webpackChunkName: "component---src-pages-speakers-james-magara-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-speakers-lucy-mbabazi-js": () => import("./../../../src/pages/speakers/LucyMbabazi.js" /* webpackChunkName: "component---src-pages-speakers-lucy-mbabazi-js" */)
}

